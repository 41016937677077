export const ROLES_BATCH_DELETE_REQUEST = "ROLES_BATCH_DELETE_REQUEST";
export const ROLES_BATCH_DELETE_SUCCESS = "ROLES_BATCH_DELETE_SUCCESS";
export const ROLES_BATCH_DELETE_FAILURE = "ROLES_BATCH_DELETE_FAILURE";

export const ROLES_FETCH_MANY_REQUEST = "ROLES_FETCH_MANY_REQUEST";
export const ROLES_FETCH_MANY_SUCCESS = "ROLES_FETCH_MANY_SUCCESS";
export const ROLES_FETCH_MANY_FAILURE = "ROLES_FETCH_MANY_FAILURE";

export const ROLES_FETCH_REQUEST = "ROLES_FETCH_REQUEST";
export const ROLES_FETCH_SUCCESS = "ROLES_FETCH_SUCCESS";
export const ROLES_FETCH_FAILURE = "ROLES_FETCH_FAILURE";

export const ROLES_SAVE_REQUEST = "ROLES_SAVE_REQUEST";
export const ROLES_SAVE_SUCCESS = "ROLES_SAVE_SUCCESS";
export const ROLES_SAVE_FAILURE = "ROLES_SAVE_FAILURE";

export const EMPTY_ROLES = "EMPTY_ROLES";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
