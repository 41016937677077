<template>
	<router-view />
</template>

<script>
import userModule from "../../store/users"
import roleModule from "../../store/roles"

export default {
	beforeCreate() {
		this.$store.registerModule("users", userModule)
		this.$store.registerModule("roles", roleModule)
	},
	destroyed() {
		this.$store.unregisterModule("users")
		this.$store.unregisterModule("roles")
	},
}
</script>
