<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? "Update" : "Create" }} User</h1>
        </b-col>
      </b-row>

      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>

      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')" label="Name">
                <b-form-input v-model="user.name" :disabled="isSaving" :state="validationState(errors.errors, 'name')"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')" label="Email Address">
                <b-form-input v-model="user.email" :disabled="isSaving" :state="validationState(errors.errors, 'email')"
                              type="email"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'department')"
                            label="Department">
                <b-form-input v-model="user.department" :disabled="isSaving"
                              :state="validationState(errors.errors, 'department')"/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'role_id')" label="Role">
                <b-form-select v-model="roleId" :disabled="isSaving" :state="validationState(errors.errors, 'role_id')">
                  <b-form-select-option :value="null">Select a role</b-form-select-option>
                  <b-form-select-option v-for="(role, roleInd) in roles" :key="`role${roleInd}`" :value="role.id">
                    {{ role.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'password')" label="Password">
                <b-form-input v-model="user.password" :disabled="isSaving" :state="validationState(errors.errors, 'password')"
                              type="password"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'password_confirmation')"
                            label="Password Confirmation">
                <b-form-input v-model="user.password_confirmation" :disabled="isSaving"
                              :state="validationState(errors.errors, 'password_confirmation')"
                              type="password"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary">
                <b-spinner v-if="isSaving" small></b-spinner>
                <span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <b-row>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation"
import {mapActions, mapGetters} from "vuex"

export default {
  mixins: [validation],
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
    this.fetchManyRoles("", "").then(response => {
      this.roles = response.data.data
    })
  },
  computed: {
    ...mapGetters("users", ["errors", "isLoading", "isSaving", "single"])
  },
  data() {
    return {
      roleId: null,
      user: {
        name: null,
        email: null,
        department: null,
        password: null,
        password_confirmation: null,
        quotes: []
      },
      roles: []
    }
  },
  methods: {
    ...mapActions("users", ["fetch", "save"]),
    ...mapActions("roles", {fetchManyRoles: "fetchMany"}),

    submit() {
      let data = {
        id: this.$route.params.id,
        name: this.user.name,
        department: this.user.department,
        email: this.user.email,
        email_confirmation: this.user.email_confirmation,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        role_id: this.roleId
      }

      this.save(data).then(response => {
        if ([200, 201].includes(response.status))
          this.$router.push({
            name: "dash.users.list",
            params: {
              notifications: [
                {
                  variant: "success",
                  title: `User #${response.data.id}`,
                  subtitle: `${response.data.name}`,
                  text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} user.`,
                  timeout: 5000
                }
              ]
            }
          })
      })
    }
  },
  watch: {
    single() {
      if (this.single.roles && this.single.roles.length) {
        this.roleId = this.single.roles[0].id
      }
      this.user = this.single
      this.user.email_confirmation = this.single.email
    }
  }
}
</script>

<style>
</style>
