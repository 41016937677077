import {
    ROLES_BATCH_DELETE_REQUEST,
    ROLES_BATCH_DELETE_SUCCESS,
    ROLES_BATCH_DELETE_FAILURE,
    ROLES_FETCH_MANY_REQUEST,
    ROLES_FETCH_MANY_SUCCESS,
    ROLES_FETCH_MANY_FAILURE,
    ROLES_FETCH_REQUEST,
    ROLES_FETCH_SUCCESS,
    ROLES_FETCH_FAILURE,
    ROLES_SAVE_REQUEST,
    ROLES_SAVE_SUCCESS,
    ROLES_SAVE_FAILURE,
    EMPTY_ROLES,
    CLEAR_ERRORS
} from "./types";

export default {
    deleteMany({ commit }, { ids = [] }) {
        commit(ROLES_BATCH_DELETE_REQUEST);
        return window.axios
            .post("/roles", { _method: "DELETE", ids })
            .then(response => {
                commit(ROLES_BATCH_DELETE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(ROLES_BATCH_DELETE_FAILURE, error);
                throw error;
            });
    },
    fetchMany({ commit }, { query = "", page = 1 }) {
        commit(ROLES_FETCH_MANY_REQUEST);
        return window.axios
            .get("/roles", { params: { query, page } })
            .then(response => {
                commit(ROLES_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(ROLES_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetch({ commit }, id) {
        commit(ROLES_FETCH_REQUEST);
        return window.axios
            .get(`/roles/${id}`)
            .then(response => {
                commit(ROLES_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(ROLES_FETCH_FAILURE, error);
                throw error;
            });
    },
    save({ commit }, role) {
        const path = role.id ? `/roles/${role.id}` : "/roles";
        const method = role.id ? "put" : "post";

        commit(ROLES_SAVE_REQUEST);
        return window.axios[method](path, role)
            .then(response => {
                commit(ROLES_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(ROLES_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(ROLES_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    empty({ commit }) {
        commit(EMPTY_ROLES);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    }
};
