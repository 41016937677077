<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="6">
          <h1 class="font-weight-bold text-primary">View User</h1>
        </b-col>
        <b-col cols="6">
          <div class="d-none d-md-flex flex-row justify-content-end pt-2">
            <b-button v-b-popover.hover.bottom="'Edit this user.'"
                      :to="{ name: 'dash.users.update', params: { id: user.id } }"
                      class="mr-1 mb-0" size="sm"
                      variant="primary">
              <b-icon icon="pen"/>
              Edit User
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>

      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')" label="Name">
                <b-form-input v-model="user.name" :state="validationState(errors.errors, 'name')" disabled/>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'email')" label="Email Address">
                <b-form-input v-model="user.email" :state="validationState(errors.errors, 'email')" disabled
                              type="email"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'role_id')" label="Role">
                <b-form-select v-model="roleId" :state="validationState(errors.errors, 'role_id')" disabled>
                  <b-form-select-option v-for="(role, roleInd) in roles" :key="`role${roleInd}`" :value="role.id">
                    {{ role.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'department')"
                            label="Department">
                <b-form-input v-model="user.department" :state="validationState(errors.errors, 'department')" disabled/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation"
import {mapActions, mapGetters} from "vuex"

export default {
  mixins: [validation],
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id)
    }
    this.fetchManyRoles("", "").then(response => {
      this.roles = response.data.data
    })
  },
  computed: {
    ...mapGetters("users", ["errors", "isLoading", "isSaving", "single"])
  },
  data() {
    return {
      collapsed: {
        customer_list: !this.$route.params.id
      },

      user: {
        name: null,
        email: null,
        department: null,
        email_confirmation: null,
        password: null
      },
      roleId: null,
      roles: []
    }
  },
  methods: {
    ...mapActions("users", ["fetch", "save"]),
    ...mapActions("roles", {
      fetchManyRoles: "fetchMany"
    })
  },
  watch: {
    single() {
      if (this.single.roles && this.single.roles.length) {
        this.roleId = this.single.roles[0].id
      }
      this.user = this.single
      this.user.email_confirmation = this.single.email
    }
  }
}
</script>

<style>
</style>
